import React from 'react';
import './Footer.css';
import logo1 from '../image/nizwa mining logo.png'; // Path to Nizwa Mining logo
import logo2 from '../image/wafra engineering logo.png'; // Path to Wafra Engineering logo
import logo3 from '../image/rumeli logo.png'; // Path to Al Rumeli Cement Products logo

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Address Section */}
        <div className="footer-section">
          <h4 className="footer-heading">Our Address</h4>
          <p className="footer-text">Post Box No 232</p>
          <p className="footer-text">Postal Code: 611</p>
          <p className="footer-text">Nizwa, Oman</p>
        </div>

        {/* Group Companies Section */}
        <div className="footer-section">
          <h4 className="footer-heading">Group Companies</h4>
          <ul>
            <li>
              <img src={logo1} alt="Nizwa Mining Logo" className="company-logo" />
              <a href="https://nizwamining.com" target="_blank" rel="noopener noreferrer">
                Nizwa Mining
              </a>
            </li>
            <li>
              <img src={logo2} alt="Wafra Engineering Logo" className="company-logo" />
              <a href="https://wafraengineering.com" target="_blank" rel="noopener noreferrer">
                Wafra Engineering LLC
              </a>
            </li>
          </ul>
        </div>

        {/* Branches Section */}
        <div className="footer-section">
          <h4 className="footer-heading">Branches & Divisions</h4>
          <ul>
            <li>
              <img src={logo3} alt="Al Rumeli Cement Products Logo" className="company-logo1" />
              <a href="https://alrumeli.com" target="_blank" rel="noopener noreferrer">
                Al Rumeli Cement Products
              </a>
            </li>
          </ul>
        </div>

        {/* Location Section */}
        <div className="footer-section">
          <h4 className="footer-heading">Location</h4>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068.1882137481816!2d57.67959757220686!3d22.873429876103597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8e56636548f52f%3A0x1b533c342b2015ab!2sAl%20Rumeli%20Cement%20Product!5e1!3m2!1sen!2som!4v1730827356919!5m2!1sen!2som"
            allowFullScreen
            title="Location"
          ></iframe>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p className="footer-text1">Copyright © 2024 nizwaeng.com. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
