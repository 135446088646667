import React from 'react';
import './CrusherRentals.css';

function CrusherRentals() {
  return (
    <div className="crusher-container">
      <h1 className="crusher-title">Mobile and Stationary Crusher Rentals</h1>
      <p className="crusher-description">
        Our crusher rental services offer a range of mobile and stationary crushers designed to handle material processing on-site, enhancing efficiency and productivity for construction projects.
      </p>

      <h2 className="crusher-subtitle">Our Crusher Rental Services Include:</h2>
      <ul className="crusher-list">
        <li>On-site mobile crusher rentals for flexible material processing.</li>
        <li>Stationary crushers for larger, long-term projects.</li>
        <li>Customizable options for various material sizes and types.</li>
      </ul>

      {/* Rate Calculator Section */}
      <div className="rate-calculator">
        <h2>Rate Calculator</h2>
        <p>
          <span className="highlight">Coming Soon:</span> Use our rate calculator to estimate crusher rental costs based on project duration and material requirements.
        </p>
      </div>
    </div>
  );
}

export default CrusherRentals;
