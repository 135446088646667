import React, { useState } from 'react';
import './AboutUs.css';

function AboutUs() {
  const [visibleSection, setVisibleSection] = useState(null);

  const toggleSection = (section) => {
    setVisibleSection(visibleSection === section ? null : section);
  };

  return (
    <div className="about-container">
      {/* Main Heading */}
      <h1 className="main-heading">About Nizwa Engineering</h1>

      {/* Intro Paragraph */}
      <div className="intro-section">
        <p>
          Welcome to <span className="hilight">Nizwa Engineering</span>, a proudly Omani-owned company delivering excellence in
          industrial and construction sectors since 1981. At Nizwa Engineering, we are the <span className="hilight">Gateway of Trust</span> ,
          providing advanced solutions tailored to meet the diverse needs of our clients.
        </p>
      </div>

      {/* Expandable Sections */}
      <div className="sections">
        <h2 className="section-title" onClick={() => toggleSection('journey')}>
          Our Journey
        </h2>
        {visibleSection === 'journey' && (
          <div className="section-content">
            <h3>Root</h3>
            <p>
              Established in 1981, Nizwa Engineering began with a vision to serve Oman’s construction industry. As a 100% Omani-owned company, we are deeply rooted in the values and resilience of our homeland.
            </p>
            <h3>Growth</h3>
            <p>
              Over the decades, our unwavering commitment to quality and customer care has driven continuous growth, expanding our services and operations to meet evolving demands.
            </p>
            <h3>Pioneer</h3>
            <p>
              Today, Nizwa Engineering stands as a pioneer in excellence, balancing innovation with ethical practices to ensure unparalleled customer satisfaction.
            </p>
          </div>
        )}

        <h2 className="section-title" onClick={() => toggleSection('expertise')}>
          Our Expertise
        </h2>
        {visibleSection === 'expertise' && (
          <div className="section-content">
            <p>We specialize in:</p>
            <ul>
              <li><strong>Stationary Crusher Solutions:</strong> Installation, erection, and commissioning, with or without operating contracts.</li>
              <li><strong>Mobile Equipment Rentals:</strong> Renting mobile crushers, screens, and related equipment with flexible operational options.</li>
              <li><strong>Heavy Machinery Rentals:</strong> Providing tippers, 3-axle trucks, lowbeds, king lowbeds, and crawler cranes with experienced operators.</li>
            </ul>
          </div>
        )}

        <h2 className="section-title" onClick={() => toggleSection('leadership')}>
          Leadership & Vision
        </h2>
        {visibleSection === 'leadership' && (
          <div className="section-content">
            <p>Under the leadership of:</p>
            <ul>
              <li><strong>Chairman:</strong> Mr. Yaqoub Yahya Sulaiman Al Kindi</li>
              <li><strong>Managing Directors:</strong> Mr. Al Julanda Al Kindi and Mr. Jaifar Al Kindi</li>
            </ul>
            <p>
              We thrive on collaboration, dedication, and technical expertise. Our team fosters a culture that emphasizes safety, growth, and a shared commitment to success.
            </p>
          </div>
        )}

        <h2 className="section-title" onClick={() => toggleSection('network')}>
          Part of a Stronger Network
        </h2>
        {visibleSection === 'network' && (
          <div className="section-content">
            <p>
              As a proud member of the <span className="hilight">Nizwa Group of Advanced Technologies & Engineering (NGate)</span>, we deliver innovative solutions that empower industries and communities alike.
            </p>
            <ul>
              <li><strong>Nizwa Mining:</strong> Specializing in raw material extraction and supply for construction and industrial needs.</li>
              <li><strong>Wafra Engineering:</strong> Expanding into government projects, focusing on crusher licenses and operations.</li>
              <li><strong>Al Rumeli Cement Products:</strong> A division of Nizwa Engineering, providing advanced concrete solutions.</li>
            </ul>
          </div>
        )}

        <h2 className="section-title" onClick={() => toggleSection('slogan')}>
          Slogan
        </h2>
        {visibleSection === 'slogan' && (
          <div className="section-content">
            <h3 className="hilight">"Nurturing Growth, Advancing Trust Everywhere"</h3>
          </div>
        )}

        <h2 className="section-title" onClick={() => toggleSection('tagline')}>
          Tagline
        </h2>
        {visibleSection === 'tagline' && (
          <div className="section-content">
            <h3 className="hilight">"As part of N-Gate, we commit to excellence, reliability, and innovation, shaping a brighter future for Oman."</h3>
          </div>
        )}

        <h2 className="section-title" onClick={() => toggleSection('description')}>
          Description
        </h2>
        {visibleSection === 'description' && (
          <div className="section-content">
            <p>
              At Nizwa Engineering, our slogan, "Nurturing Growth, Advancing Trust Everywhere (N-Gate)," reflects our mission to support industries and communities through reliable, innovative, and sustainable engineering solutions.
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default AboutUs;
