import React from 'react';
import './ContactUs.css';

function ContactUs() {
  const emailSubject = "Inquiry about Nizwa Engineering Services";
  const emailBody = "Hello, I am interested in learning more about your services. Please provide more details.";
  const whatsappMessage = "Hello, I would like to inquire about your services. Could you please provide more details?";

  return (
    <div className="contact-us">
      <h2 className="contact-heading">Contact Us</h2>
      <p className="contact-description">
        Connect with the right department to address your needs. Our team is committed to providing prompt and professional assistance.
      </p>

      {/* Contact Cards */}
      {[
        {
          title: "Sales Department",
          description: "For inquiries related to transportation, crusher rentals, and block delivery services:",
          email: "sales@nizwaeng.com",
          phone: "+968 9268 1010",
          whatsappLink: "https://wa.me/96892681010",
        },
        {
          title: "Purchasing Department",
          description: "For supplier inquiries, partnerships, and procurement of goods and services:",
          email: "purchase@nizwaeng.com",
          phone: "+968 9284 4415",
          whatsappLink: "https://wa.me/96892844415",
        },
        {
          title: "Quality Control",
          description: "For feedback and quality assurance-related concerns:",
          email: "qc@nizwaeng.com",
          phone: "+968 9610 8883",
          whatsappLink: "https://wa.me/96896108883",
        },
      ].map((dept, index) => (
        <div className="contact-card" key={index}>
          <h3 className="contact-card-title">{dept.title}</h3>
          <p className="contact-card-description">{dept.description}</p>
          <div className="contact-detail">
            <i className="fas fa-envelope contact-icon"></i>
            <span>Email:</span>
            <a
              href={`mailto:${dept.email}?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`}
              className="email-link"
            >
              {dept.email}
            </a>
          </div>
          <div className="contact-detail">
            <i className="fab fa-whatsapp contact-icon"></i>
            <span>Phone:</span>
            <a
              href={`${dept.whatsappLink}?text=${encodeURIComponent(whatsappMessage)}`}
              className="whatsapp-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {dept.phone}
            </a>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ContactUs;
