import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navebar.css';
import img1 from '../image/NE logo copy.png';

function Navebar() {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggles the menu open/close state
  const toggleMenu = () => setMenuOpen(!menuOpen);

  // Closes the menu when a link is clicked
  const closeMenu = () => setMenuOpen(false);

  return (
    <nav className="navbar">
      <div className="inner-navbar">
        {/* Logo Section */}
        <div className="navbar-logo">
          <img
            className="logo-image"
            src={img1}
            alt="Nizwa Engineering Logo"
          />
        </div>

        {/* Heading Section */}
        <h1 className="heading">NIZWA ENGINEERING</h1>

        {/* Hamburger Menu */}
        <div className="hamburger" onClick={toggleMenu}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>

        {/* Navigation Links */}
        <div className={`navbar-links ${menuOpen ? 'open' : ''}`}>
          <Link to="/" className="nav-link" onClick={closeMenu}>Home</Link>
          <Link to="/services" className="nav-link" onClick={closeMenu}>Services</Link>
          <Link to="/contact-us" className="nav-link" onClick={closeMenu}>Contact Us</Link>
          <Link to="/about-us" className="nav-link" onClick={closeMenu}>About Us</Link>
        </div>
      </div>
    </nav>
  );
}

export default Navebar;
