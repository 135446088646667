import React from 'react';
import './CementBulker.css';

function CementBulker() {
  return (
    <div className="bulker-container">
      <h1 className="bulker-title">Cement Bulker Logistics</h1>
      <p className="bulker-description">
        Our cement bulker service provides specialized transport solutions for bulk cement deliveries. We ensure safe and timely deliveries that meet the unique requirements of construction sites and large projects.
      </p>

      <h2 className="bulker-subtitle">Our Cement Bulker Services Offer:</h2>
      <ul className="bulker-list">
        <li>Secure transport of bulk cement for construction projects.</li>
        <li>Timely and reliable delivery to maintain project schedules.</li>
        <li>Customizable options to meet project-specific needs.</li>
      </ul>

      {/* Rate Calculator Section */}
      <div className="rate-calculator">
        <h2>Rate Calculator</h2>
        <p>
          <span className="highlight">Coming Soon:</span> Calculate logistics costs for cement bulk delivery based on quantity and destination.
        </p>
      </div>
    </div>
  );
}

export default CementBulker;
