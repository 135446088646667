import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './navebar';
import Home from './pages/Home';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import AboutUs from './pages/AboutUs';
import BlockDelivery from '../components/pages/BlockDelivery';
import CrusherRentals from './pages/CrusherRentals';
import EquipmentTransportation from './pages/EquipmentTransportation';
import CementBulker from './pages/CementBulker';
import RCPServices from './pages/RCPServices';
import { Akhil } from '../Contacts/Contactindex';
import { Julanda } from '../Contacts/Contactindex';
import { Masi } from '../Contacts/Contactindex';
import { Rinu } from '../Contacts/Contactindex';
import { Haitham } from '../Contacts/Contactindex';
import Footer from './Footer';
import '../App.css';

function AppWrapper() {
  const location = useLocation();

  // Routes where Navbar and Footer should not be visible
  const hideLayoutRoutes = [
    '/contactindex/Akhil',
    '/contactindex/Julanda',
    '/contactindex/Masi',
    '/contactindex/Rinu',
    '/contactindex/Haitham',
  ];

  const isLayoutHidden = hideLayoutRoutes.includes(location.pathname);

  return (
    <div>
      {/* Conditionally render Navbar */}
      {!isLayoutHidden && <Navbar />}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/concrete-products" element={<BlockDelivery />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />

        {/* Service Detail Routes */}
        <Route path="/services/crusher-rentals" element={<CrusherRentals />} />
        <Route path="/services/cement-bulker" element={<CementBulker />} />
        <Route path="/services/equipment-transportation" element={<EquipmentTransportation />} />
        <Route path="/services/rcp-services" element={<RCPServices />} />

        {/* Contact Pages */}
        <Route path="/contactindex/Akhil" element={<Akhil />} />
        <Route path="/contactindex/Julanda" element={<Julanda />} />
        <Route path="/contactindex/Masi" element={<Masi />} />
        <Route path="/contactindex/Rinu" element={<Rinu />} />
        <Route path="/contactindex/Haitham" element={<Haitham />} />
      </Routes>

      {/* Conditionally render Footer */}
      {!isLayoutHidden && <Footer />}
    </div>
  );
}

export default AppWrapper;
