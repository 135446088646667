import React from 'react';
import './EquipmentTransportation.css';

function EquipmentTransportation() {
  return (
    <div className="transportation-container">
      <h1 className="transportation-title">Transportation</h1>
      <p className="transportation-description">
        Our equipment transportation service offers comprehensive logistics solutions, utilizing a diverse fleet of vehicles to meet your specific needs. 
        We provide specialized trailers, including flat trailers, four-axle king lowbeds, three-axle trailers, and tippers, ensuring efficient and safe 
        transportation of heavy and oversized loads.
      </p>

      {/* Rate Calculator Section */}
      <div className="rate-calculator">
        <h2>Rate Calculator</h2>
        <p>
          <span className="highlight">Coming Soon:</span> Calculate transportation rates based on distance and load weight for a tailored estimate.
        </p>
      </div>
    </div>
  );
}

export default EquipmentTransportation;
