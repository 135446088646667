// RCPServices.js
import React, { useState } from 'react';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import './RCPServices.css';

const mapContainerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = { lat: 22.9333, lng: 57.5333 }; // Nizwa, Oman

function RCPServices() {
  const [showQuoteForm, setShowQuoteForm] = useState(false);
  const [orderItems, setOrderItems] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showThankYou, setShowThankYou] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [showMapPopup, setShowMapPopup] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyBOinZQxFkBmDcmB5s_0A8gONDN4pEMbas', // Replace with your actual API key
  });

  const blockDetails = [
    {
      id: 1,
      name: '8 Inch Hollow Block',
      dimensions: '390mm x 190mm x 190mm',
      weight: '20.100 kg',
      bundleSize: '75 pcs',
      imagePath: '/Models/8H.png',
    },
    {
      id: 2,
      name: '6 Inch Hollow Block',
      dimensions: '390mm x 190mm x 140mm',
      weight: '16.500kg',
      bundleSize: '90 pcs',
      imagePath: '/Models/6H.png',
    },
    {
      id: 3,
      name: '4 Inch Hollow Block',
      dimensions: '390mm x 190mm x 90mm',
      weight: '10.050 kg',
      bundleSize: '150 pcs',
      imagePath: '/Models/4H.png',
    },
    {
      id: 4,
      name: '8 Inch Solid Block',
      dimensions: '390mm x 190mm x 190mm',
      weight: '27.700 kg',
      bundleSize: '75 pcs',
      imagePath: '/Models/8S.png',
    },
    {
      id: 5,
      name: '6 Inch Solid Block',
      dimensions: '390mm x 190mm x 140mm',
      weight: '21.300 kg',
      bundleSize: '90 pcs',
      imagePath: '/Models/6S.png',
    },
    {
      id: 3,
      name: '4 Inch Solid Block',
      dimensions: '390mm x 190mm x 90mm',
      weight: '13.800 kg',
      bundleSize: '150 pcs',
      imagePath: '/Models/4S.png',
    },
    // ... more block details
  ];

  const handleCheckboxChange = (blockName, isChecked) => {
    if (isChecked) {
      setOrderItems([...orderItems, { blockType: blockName, quantity: 0 }]);
    } else {
      setOrderItems(orderItems.filter((item) => item.blockType !== blockName));
    }
  };

  const handleQuantityChange = (blockName, quantity) => {
    const updatedOrderItems = orderItems.map((item) =>
      item.blockType === blockName ? { ...item, quantity: parseInt(quantity) || 0 } : item
    );
    setOrderItems(updatedOrderItems);
  };

  const handleMapClick = (event) => {
    const { lat, lng } = event.latLng.toJSON();
    setSelectedLocation({ lat, lng });
  };

  const handleConfirmLocation = () => {
    setShowMapPopup(false);
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();

    // Generate a formatted quote request message
    let emailBody = "Dear Nizwa Engineering,\n\nI would like to request a quote for the following concrete products:\n\n";
    orderItems.forEach((item) => {
      if (item.quantity > 0) {
        emailBody += `${item.blockType} - Quantity: ${item.quantity} ${item.blockType === '4 Inch Solid Block' ? 'pcs' : 'bundles'}\n`;
      }
    });

    // Add location information if available
    if (selectedLocation) {
      emailBody += `\nDelivery Location: Latitude ${selectedLocation.lat}, Longitude ${selectedLocation.lng}`;
    }

    emailBody += `\nName: ${name}\nEmail: ${email}\nMessage: ${message}\n\nSincerely,`;

    // Open the user's email client with the pre-filled subject and body
    window.open(`mailto:sales@nizwaeng.com?subject=Quote Request&body=${encodeURIComponent(emailBody)}`);
  };

  const handleWhatsAppSubmit = (e) => {
    e.preventDefault();

    // Generate a formatted quote request message
    let whatsappMessage = "Dear Nizwa Engineering,%0A%0AI would like to request a quote for the following concrete products:%0A%0A";
    orderItems.forEach((item) => {
      if (item.quantity > 0) {
        whatsappMessage += `${item.blockType} - Quantity: ${item.quantity} ${item.blockType === '4 Inch Solid Block' ? 'pcs' : 'bundles'}%0A`;
      }
    });

    // Add location information if available
    if (selectedLocation) {
      whatsappMessage += `%0ADelivery Location: Latitude ${selectedLocation.lat}, Longitude ${selectedLocation.lng}`;
    }

    whatsappMessage += `%0AName: ${name}%0AEmail: ${email}%0AMessage: ${message}%0A%0ASincerely,`;

    // Open WhatsApp with the pre-filled message
    window.open(`https://wa.me/+96892681010?text=${whatsappMessage}`);
  };

  return (
    <div className="rcp-services-container">
      <h1>Sale of Concrete Products</h1>
      <p>Explore our high-quality concrete blocks and submit an inquiry to receive a quote.</p>

      {/* Block Details Section */}
      <div className="block-details-section">
        <div className="block-grid">
          {blockDetails.map((block) => (
            <div key={block.id} className="block-item">
              <img src={block.imagePath} alt={block.name} className="block-image" />
              <div className="block-details">
                <h4>{block.name}</h4>
                <p>Dimensions: {block.dimensions}</p>
                <p>Weight: {block.weight}</p>
                <p>Bundle Size: {block.bundleSize}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Request for Quote Button */}
      <button className="request-quote-button" onClick={() => setShowQuoteForm(true)}>
        Request for Quote
      </button>

      {/* Quote Request Form Popup */}
      {showQuoteForm && (
        <div className="quote-form-popup">
          <div className="quote-form-container">
            {/* Close button on the right */}
            <button className="close-button" onClick={() => setShowQuoteForm(false)}>
              &times;
            </button>

            {/* Heading for the Quote Form */}
            <div className="quote-form-heading">
              <h3>Request a Quote</h3>
              <p>Please select the types and quantities.</p>
            </div>

            <form>
              <div className="checkbox-grid">
                {blockDetails.map((block) => (
                  <div key={block.id} className="block-checkbox">
                    <input
                      type="checkbox"
                      id={block.id}
                      value={block.name}
                      onChange={(e) => handleCheckboxChange(block.name, e.target.checked)}
                    />
                    <label htmlFor={block.id}>{block.name}</label>
                    {orderItems.find((item) => item.blockType === block.name) ? (
                       <input
                        type="number"
                        min="0"
                        placeholder="Quantity"
                        value={orderItems.find((item) => item.blockType === block.name).quantity}
                        onChange={(e) => handleQuantityChange(block.name, e.target.value)}
                      />
                    ) : null}
                  </div>
                ))}
              </div>

              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />

              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              <label htmlFor="message">Message:</label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows="4"
              />

              {/* Location Selection */}
              <button type="button" onClick={() => setShowMapPopup(true)} className="select-location-button">
                Select Delivery Location
              </button>

              {selectedLocation && (
                <p className="selected-location">
                  Selected Location: Latitude {selectedLocation.lat}, Longitude {selectedLocation.lng}
                </p>
              )}

              {/* Email and WhatsApp Buttons */}
              <div className="submit-buttons">
                <button type="button" className="email-button" onClick={handleEmailSubmit}>
                  <i className="fas fa-envelope"></i> Email
                </button>
                <button type="button" className="whatsapp-button" onClick={handleWhatsAppSubmit}>
                  <i className="fab fa-whatsapp"></i> WhatsApp
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Map Popup */}
      {showMapPopup && isLoaded && (
        <div className="map-popup">
          <div className="map-container"> {/* Added a container for the map and close button */}
            <button className="map-close-button" onClick={() => setShowMapPopup(false)}>
              &times;
            </button>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={defaultCenter}
              zoom={12}
              onClick={handleMapClick}
            >
              {selectedLocation && <Marker position={selectedLocation} />}
            </GoogleMap>
          </div>
          <div className="map-buttons">
            <button onClick={handleConfirmLocation} disabled={!selectedLocation}>
              Confirm Location
            </button>
            <button onClick={() => setShowMapPopup(false)}>Cancel</button>
          </div>
        </div>
      )}


      {/* Thank You Message */}
      {showThankYou && (
        <div className="thank-you-popup">
          <div className="thank-you-message">
            <h2>Thank You!</h2>
            <p>Your quote request has been received.</p>
            <p>You will receive a response from the boat "Rumeli" soon.</p>
            <button onClick={() => setShowThankYou(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default RCPServices;